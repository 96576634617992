import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import styled, { keyframes } from "styled-components";
import Img from "gatsby-image";
import { PageTitle } from "../globals/style";

const floatIn = keyframes`
  from{ transform:translateY(50%); opacity: 0;}
  to{ transform:translateY(0); opacity: 1;}
`;
const BlogPosts = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 2em;
  justify-content: center;
  @media screen and (max-width: 991px) {
    padding: 2em 0.2em;
    justify-content: center;
  }
  display: flex;
  flex-wrap: wrap;
  background: #efefef;
`;
const SinglePost = styled(Link)`
  @media screen and (max-width: 991px) {
    margin: 1em 0;
  }
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.04),
    0 4px 4px rgba(0, 0, 0, 0.04), 0 8px 8px rgba(0, 0, 0, 0.04),
    0 16px 16px rgba(0, 0, 0, 0.04);
  animation: ${floatIn} 0.3s ease;
  animation-delay: ${props => (props.index ? props.index / 4 : 0)}s;
  width: 95vw;
  margin: 1em;
  max-width: 350px;
  min-height: 350px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
`;
const PostDate = styled.h5`
  margin: 0;
  margin-top: -1.5em;
  z-index: 2;
  background: #efefef;
  position: absolute;
  padding: 1em;
  color: #3f6564;
`;
const HeaderImg = styled(Img)`
  display: block;
  flex: 1;
  min-height: 200px;
`;
const PostSubContent = styled.div`
  color: initial;
  backgruond: #efefef;
  padding: 1rem;
  outline-style: outset;
  & > h1 {
    font-size: 1.3rem;
    margin-top: 0.5em;
    margin-bottom: 0.3em;
    color: #3f6564;
  }
  & > p {
    color: rgba(0, 0, 0, 0.8);
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    -webkit-line-clamp: 4;
    max-height: 6em;
  }
`;
const Pagination = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  margin: 2em 0;
  & a {
    text-decoration: none !important;
    font-weight: 600;
  }
  & a:hover a:active a:visited {
    color: inital;
  }
`;
const PageWrapper = styled.div`
  background: #efefef;
  width: 100%;
  height: 100%;
  padding: 4em 0;
`;
const StyledPageTitle = styled(PageTitle)`
  max-width: 1440px;
  margin:0em auto 0 1em;
  @media screen and (max-width: 991px) {
    margin: 0 0.5em;
  }
`;
const Button = styled.button`
  background: #3f6564;
  color: #efefef;
  border-radius: 25px;
  border: 0;
  padding: 0.3em 2em;
  cursor: pointer;
`;

const getUrl = (node) => `/artikkel/${node.Title
  .toLowerCase()
  .split(" ")
  .join("-")
  .replace(/[^a-zæøåA-ZÆØÅ0-9-_\-]/g, "")}/`


const IndexPage = props => {
  const postList = props.data.allStrapiBloggs;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <Layout
      title="Nyheter"
      description="Hold deg oppdatert med alt som skjer hos VI§VE advokater. Les en av våre innlegg her"
      keywords={["Blogg", "VI§VE Nyheter", "VIVE", "Advokat", "Nyheter"]}
    >
      <PageWrapper>
        <StyledPageTitle>Siste nytt</StyledPageTitle>
        <BlogPosts>
          {postList.edges.map(({ node }, i) => (
            <SinglePost
              index={i}
              to={getUrl(node)}
              key={`blog-item-${i}`}
            >
              <HeaderImg
                fadeIn
                fluid={node.Image.childImageSharp.fluid}
                alt={node.Title}
              />
              <PostDate>{node.date}</PostDate>
              <PostSubContent>
                <h1>{node.Title}</h1>
                <p>{node.Description}</p>
                <Button>Les mer</Button>
              </PostSubContent>
            </SinglePost>
          ))}
        </BlogPosts>
        <Pagination>
          {!isFirst && (
            <Link to={`/blogg/${prevPage}/`} rel="prev">
              Forrige innlegg
            </Link>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <li key={`pagination-number${i + 1}`}>
              <Link to={`/blogg/${i === 0 ? "" : i + 1}/`}>{i + 1}</Link>
            </li>
          ))}
          {!isLast && (
            <Link to={`/blogg/${nextPage}/`} rel="next">
              Neste innlegg
            </Link>
          )}
        </Pagination>
      </PageWrapper>
    </Layout>
  );
};

export default IndexPage;

export const listQuery = graphql`
  query allBlogPostsList($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiBloggs(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: [date] }
    ) {
      edges {
        node {
          date(formatString: "MMMM Do YYYY", locale: "nb-NO")
          Title
          Description
          Image {
            childImageSharp {
              resize(width: 800, height: 450) {
                src
              }
              fluid(maxWidth: 800) {
                src
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;
